

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import {LoanOfficerData} from "../../../models/broker/loan-officer.model"
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";


@Component({
 components: {CommonUserChoiceModal },
})
export default class LoanOfficer extends Vue {
  /* eslint-disable */
public LoanOfficerData: any = new LoanOfficerData();
public emailExist = false;
public LoanOfficerList: any = [];





  public async saveLoanOfficer() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/loan-officer/saveLoanOfficer",
        this.LoanOfficerData,
        { headers: authHeader() }
      );
      if (response.data.status == 201) {
        this.$snotify.success("You have been registered successfully. Verification mail has been sent to you email.");
        this.$modal.hide("addLoanOfficer"); 
        this.getLoanOfficer();
      } else if (response.data.status == 400) {
        this.emailExist = response.data.emailExist;
        this.$snotify.error("Email Address already Exist.");
      } else {
        this.$snotify.error("Something went wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  }

    public async getLoanOfficer() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}broker/loan-officer/getLoanOfficer`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.LoanOfficerList = response.data.userData
      }
    } catch (error) {
      console.log(error);
    }
  }


    public async resendLinkToLoanOfficer(data) {
    try {
      const LoanOfficerData = {
        email: data.email,
        userId: data.userId,
        fullName: data.fullName,
      };
      const response = await Axios.post(
        `${BASE_API_URL}broker/loan-officer/resendLinkToLoanOfficer`,
        LoanOfficerData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success("Verification link has been re-sent to your email.");
      }
    } catch (error) {
      console.log(error);
    }
  }

 public async activateDeActivateLoanOfficer(data) {
    try {
      const selectedUser = data;
      const status = selectedUser.isUserActive ? "Active" : "De Activate";
      await this.$refs.refrence["confirmationBox"]({
        msg: `Are you sure want to ${status} the ${selectedUser.fullName.firstName} ${selectedUser.fullName.lastName} Loan Officer ?`,
        data: {
          data: data,
        },
        apiPath: "broker/loan-officer/activateDeActivateLoanOfficer",
      });
    } catch (error) {
      console.log(error);
    }
  }



  public openLoanOfficerModel() {
    try {
      this.$modal.show("addLoanOfficer");    
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.$modal.hide("addLoanOfficer");
  }
  async mounted() {
   await this.getLoanOfficer(); 
  }
  /* eslint-disable */
}
