import fullName from '../common/name.model'


export class LoanOfficerData {
    constructor() {
        this.brokerId = null;
        this.fullName = new fullName();
        this.email = null;
        this.phone = null;
        this.individualNMLS = null;
   
        
    }

    public brokerId: any;
    public fullName: fullName;
    public email: any;
    public phone: any;
    public individualNMLS: any;

   

    


}